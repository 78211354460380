export class EmbedService {
  constructor(optionsArray) {
    this.iframe = null;
    this.container = null;
    this.closeButton = null;
    if (optionsArray && optionsArray.length) {
      this.push(optionsArray[optionsArray.length - 1]);
    }
  }
  dispose() {
    if (this.iframe) {
      this.iframe.remove();
      this.iframe = null;
    }
    if (this.closeButton) {
      this.closeButton.remove();
      this.closeButton = null;
    }
    if (this.container) {
      this.container.remove();
      this.container = null;
    }
    if (this.mediaQuery) {
      this.mediaQuery.removeEventListener(
        'change',
        this.mediaQueryChangeListener
      );
      this.mediaQuery = null;
      this.mediaQueryChangeListener = null;
    }
  }
  push(options) {
    this.dispose();
    if (!options) {
      return;
    }
    this.container = document.createElement('div');
    this.closeButton = document.createElement('button');
    this.iframe = document.createElement('iframe');
    // Build query string
    const query = new URLSearchParams();
    if (options.ratings) {
      options.ratings.forEach((r) => {
        query.append('rating', r);
      });
    }
    if (options.locationId) {
      query.append('location_id', options.locationId);
    }
    if (options.reviewSites) {
      options.reviewSites.forEach((rid) => {
        query.append('review_site_id', rid);
      });
    }
    // Set iframe src
    // eslint-disable-next-line no-undef
    this.iframe.src = `${SOCIAL_PROOF_URL}/?${query.toString()}`;
    this.iframe.title = 'fiveoak Review Carousel';
    this.iframe.style.left = '0';
    this.iframe.style.top = '0';
    this.iframe.style.width = '100%';
    this.iframe.style.height = '100%';
    this.iframe.style.position = 'absolute';
    this.iframe.style.border = '0';

    this.closeButton.innerHTML = '&times;';
    this.closeButton.style.right = '15px';
    this.closeButton.style.top = '15px';
    this.closeButton.style.position = 'absolute';
    this.closeButton.style.background = 'transparent';
    this.closeButton.style.border = '0';
    this.closeButton.style.fontSize = '16px';
    this.closeButton.style.lineHeight = '16px';
    this.closeButton.style.cursor = 'pointer';
    this.closeButton.style.padding = '7px';
    this.closeButton.style.margin = '0';
    this.closeButton.style.opacity = '0';
    this.closeButton.style.transition = 'opacity ease-in-out 0.5s';

    this.container.style.maxWidth = '100%';
    this.container.style.width = '354px';
    this.container.style.height = '116px';
    this.container.style.position = 'fixed';
    if (options.zIndex) {
      this.container.style.zIndex = options.zIndex;
    } else {
      this.container.style.zIndex = '1000';
    }
    if (options.positon && options.position.y === 'top') {
      this.container.style.top = '0px';
    } else if (options.position && options.position.y === 'bottom') {
      this.container.style.bottom = '0px';
    }
    document.body.appendChild(this.container);
    this.container.appendChild(this.iframe);
    // Wait for iframe to load before we show our close button
    this.iframe.addEventListener('load', () => {
      this.container.appendChild(this.closeButton);
      this.closeButton.addEventListener('click', (e) => {
        e.preventDefault();
        this.dispose();
        return false;
      });
      setTimeout(() => {
        this.closeButton.style.opacity = '1';
      }, 1000);
    });
    this.mediaQuery = window.matchMedia('(min-width: 480px)');
    this.mediaQueryChangeListener = (e) => {
      if (e.matches) {
        this.container.style.transform = '';
        if (options.position && options.position.x === 'left') {
          this.container.style.left = '0px';
        } else if (options.position && options.position.x === 'right') {
          this.container.style.right = '0px';
          this.container.style.left = '';
        }
      } else {
        this.container.style.right = '';
        this.container.style.left = '50%';
        this.container.style.transform = 'translateX(-50%)';
      }
    };
    this.mediaQuery.addEventListener('change', this.mediaQueryChangeListener);
    this.mediaQueryChangeListener(this.mediaQuery);
  }
}
